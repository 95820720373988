/*///////////////////////////////////////////////////// Header Style \\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\*/
.header-navbar-main {
	border-bottom: 1px solid rgb(242, 242, 242) !important;
	padding: 10px 60px !important;
	background-color: #fff !important;
}

.modal {
	padding: 80px 0px;
}
.modal-close-icon {
	position: absolute;
	top: -100px;
	right: -90px;
	cursor: pointer;
	color: #fff;
	font-size: 30px;
}
.modal-body {
	height: 400px;
	display: flex;
}
.modal-title {
	padding-left: 40px;
}

.modal-form {
	width: 200%;
	display: flex;
	flex-direction: column;
	padding: 0px 50px;
}
.modal-form label {
	color: gray;
	font-size: 17px;
	font-weight: 400;
}
.modal-form input {
	margin-top: 10px;
	outline: none;
	padding: 7px 10px;
	border-radius: 10px;
	border: 2px solid gray;
	margin-bottom: 30px;
}

.modal-form button {
	display: block;
	width: 100%;
	padding: 13px 20px;
	/* height: 50px; */
	margin-top: 7px;
	border: none;
	outline: #17b978;
	background-color: #17b978;
	color: #fff;
	border-radius: 10px;
	-webkit-transform: translateZ(0);
	transform: translateZ(0);
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
	-moz-osx-font-smoothing: grayscale;
	position: relative;
	-webkit-transition-property: color;
	transition-property: color;
	-webkit-transition-duration: 0.5s;
	transition-duration: 0.5s;
}

.modal-form button:before {
	content: '';
	position: absolute;
	z-index: -1;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	border-radius: 10px;
	background: #17b978;
	-webkit-transform: scaleX(1);
	transform: scaleX(0);
	-webkit-transform-origin: 0 50%;
	transform-origin: 0 50%;
	-webkit-transition-property: transform;
	transition-property: transform;
	-webkit-transition-duration: 0.5s;
	transition-duration: 0.5s;
	-webkit-transition-timing-function: ease-out;
	transition-timing-function: ease-out;
}
.modal-form button:hover,
.modal-form button:focus,
.modal-form button:active {
	color: #fff;
}
.modal-form button:hover:before,
.modal-form button:focus:before,
.modal-form button:active:before {
	-webkit-transform: scaleX(1);
	transform: scaleX(1);
	-webkit-transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
	transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
	border-radius: 10px;
	background-color: #17b978;
}

.modal-body-right-container {
	display: flex;
	align-items: center;
	flex-direction: column;
	position: absolute;
	background-color: #f4f7fd;
	top: -70px;
	right: -80px;
	height: 117.5%;
	padding: 10px 0px;
	box-sizing: border-box;
	border-radius: 0px 8px 8px 0px;
}

.modal-body-right-item {
	width: 80%;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-top: 12.5px;
	height: 95px;
}

.modal-body-right-item img {
	margin-right: 25px;
}
.modal-body-right-item-text {
	width: 300px;
}
.modal-body-right-item-text h5 {
	font-size: 17px;
	font-weight: 700;
}

.modal-body-right-item-text span {
	color: gray;
	font-weight: 400;
}

.navbar-search {
	z-index: 20;
	position: relative;
	width: 100%;
	display: flex;
	align-items: center;
	border-radius: 15px;
	height: 40px;
	margin-top: 10px;
}

.navbar-search form {
	z-index: 20;
	width: 100%;
	margin: 0 10px;
	background-color: #17b978;
	border-radius: 10px;
}

.navbar-search form input {
	border: 2px solid #17b978;
	z-index: 2;
	outline: #17b978;
	border-radius: 10px;
}
.navbar-search form input:focus {
	box-shadow: none !important;
	outline: none !important;
}
.navbar-search form button {
	border: none;
	outline: none;
	background-color: transparent;
	color: #fff;
	border-bottom-right-radius: 10px;
	border-top-right-radius: 10px;
	width: 5%;
	min-width: 100px;
	transition: 0.5s;
	margin-left: -15px;
}
.navbar-search form button:active:focus {
	background-color: #17b978;
}
.navbar-search form button:hover {
	background-color: #17b978;
}
.header-link {
	font-weight: 700;
	color: #000;
	text-decoration: none;
	display: flex;
	flex-direction: column;
	align-items: center;
	transition: 0.5s;
	margin-left: 30px;
	margin-top: 8px;
	cursor: pointer;
}

.header-page-link {
	font-weight: 700;
	text-decoration: none;
	color: #000;
	transition: 0.5s;
}
.header-page-link:hover {
	color: #17b978;
}

.left-side-icon-responsive {
	font-size: 25px;
}

.header-link:hover {
	color: #17b978;
}

.header-language-container {
	display: flex;
	align-items: center;
	flex-direction: column;
	margin-top: 8px;
	transition: 0.5s;
	margin-left: 20px;
}

.header-language-container:hover {
	color: #17b978;
}

.language-dropdown {
	color: #000;
}

.navbar-search-bottom {
	display: none;
}

.navbar-toggle {
	border: none !important;
	outline: none !important;
}

.header-language-container-desktop {
	display: none;
}
.header-link-responsive-flex {
	display: none;
}

.left-side-bar-network {
	display: none;
}

.shop-hover-top {
	width: 100%;
	height: 45px;
	background-color: #fff;
	border-radius: 15px 15px 0 0;
}

.shop-hover-bottom .shop-status {
	width: 100%;
	height: 40px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 10px 20px;
}
.shop-hover-bottom .shop-hover-button {
	width: 100%;
	padding: 0 15px;
	padding-top: 20px;
}
/* button1 style and hover style  */
.shop-hover-bottom .button1 {
	display: block;
	width: 100%;
	height: 50px;
	margin-top: 7px;
	border: none;
	outline: none;
	background-color: #17b978;
	color: #fff;
	border-radius: 10px;
	-webkit-transform: translateZ(0);
	transform: translateZ(0);
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
	-moz-osx-font-smoothing: grayscale;
	position: relative;
	-webkit-transition-property: color;
	transition-property: color;
	-webkit-transition-duration: 0.5s;
	transition-duration: 0.5s;
}

.shop-hover-bottom .button1:before {
	content: '';
	position: absolute;
	z-index: -1;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	border-radius: 10px;
	background: #17b978;
	-webkit-transform: scaleX(1);
	transform: scaleX(0);
	-webkit-transform-origin: 0 50%;
	transform-origin: 0 50%;
	-webkit-transition-property: transform;
	transition-property: transform;
	-webkit-transition-duration: 0.5s;
	transition-duration: 0.5s;
	-webkit-transition-timing-function: ease-out;
	transition-timing-function: ease-out;
}
.shop-hover-bottom .button1:hover,
.shop-hover-bottom .button1:focus,
.shop-hover-bottom .button1:active {
	color: #fff;
}
.shop-hover-bottom .button1:hover:before,
.shop-hover-bottom .button1:focus:before,
.shop-hover-bottom .button1:active:before {
	-webkit-transform: scaleX(1);
	transform: scaleX(1);
	-webkit-transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
	transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
	border-radius: 10px;
	background-color: #17b978;
}
/* button1 style and hover style end */

/* Button2 style and hover style */
.shop-hover-bottom .button2 {
	display: block;
	width: 100%;
	height: 50px;
	margin-top: 7px;
	border: none;
	outline: none;
	background-color: #fff;
	color: #000;
	border-radius: 10px;
	-webkit-transform: translateZ(0);
	transform: translateZ(0);
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
	-moz-osx-font-smoothing: grayscale;
	position: relative;
	-webkit-transition-property: color;
	transition-property: color;
	-webkit-transition-duration: 0.5s;
	transition-duration: 0.5s;
}

.shop-hover-bottom .button2:before {
	content: '';
	position: absolute;
	z-index: -1;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	border-radius: 10px;
	background: #fff;
	-webkit-transform: scaleX(1);
	transform: scaleX(0);
	-webkit-transform-origin: 0 50%;
	transform-origin: 0 50%;
	-webkit-transition-property: transform;
	transition-property: transform;
	-webkit-transition-duration: 0.5s;
	transition-duration: 0.5s;
	-webkit-transition-timing-function: ease-out;
	transition-timing-function: ease-out;
}
.shop-hover-bottom .button2:hover,
.shop-hover-bottom .button2:focus,
.shop-hover-bottom .button2:active {
	color: #fff;
}
.shop-hover-bottom .button2:hover:before,
.shop-hover-bottom .button2:focus:before,
.shop-hover-bottom .button2:active:before {
	-webkit-transform: scaleX(1);
	transform: scaleX(1);
	-webkit-transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
	transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
	border-radius: 15px;
	background-color: #17b978;
}
/* Button2 style and hovere style end */

/* shop hoverable status */
.shop-hover-container {
	background-color: #f1f9ff;
	display: none;
	width: 400px;
	height: 230px;
	padding: 0px;
	position: absolute;
	border-radius: 15px;
	top: 65px;
	right: 200px;
	box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
	z-index: 2;
}
.shop-hover-container:hover {
	display: block;
}

.shop-hover:hover + .shop-hover-container {
	display: block;
	animation: shop 0.4s ease;
}
@keyframes shop {
	from {
		width: 400px;
		height: 230px;
		transform: scale(0);
	}
	to {
		width: 400px;
		height: 230px;
		transform: scale(1);
	}
}
.search-block {
	z-index: 5;
	position: absolute;
	top: 0;
	left: 1.1%;
	width: 97.7%;
	max-height: 500px;
	background-color: rgb(255, 255, 252);
	/* z-index: 1; */
	/* left: 410px; */
	box-shadow: 0px 0px 2px 0px grey;
	border-radius: 10px;
	overflow: auto;
	padding: 10px 10px 10px 10px;
	display: block;
}
.menu-none {
	display: none;
}
.search-block ul {
	list-style: none;
	position: relative;
	top: 7px;
	left: -30px;
	align-items: center;
}
.search-block img {
	display: flex;
	width: 50px;
}
.search-block li {
	list-style: none;
	align-items: center;
	justify-content: center;
	margin: 10px;
	color: black;
	font-weight: 700;
}
.search-block .box {
	z-index: 1000;
	display: flex;
	/* justify-content: left; */
	align-items: center;
	width: 100%;
	height: 20%;
	background-color: rgb(255, 255, 252);
	position: relative;
	top: 50px;
}
::placeholder {
	position: relative;
	top: 1px;
	font-weight: 500;
}
/* shop hoverable status end */
/*///////////////////////////////////////////////////////// Header Style end \\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\*/

@media (max-width: 992px) {
	.modal{
		display: none;
	}
	/*///////////////////////////////////////////////////////// Header Style responsive \\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\*/
	.modal-close-icon {
		display: none;
	}
	.modal-form {
		max-width: 100%;
	}
	.modal-body {
		
		height: 200px;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
	}
	.modal-title {
		padding: 0;
	}
	.modal-body-right-container {
		display: none;
	}
	.header-navbar-main {
		z-index: 150;
		position: sticky !important;
		top: 0px;
		padding: 10px 10px !important;
	}
	.navbar-search-bottom {
		display: flex;
		margin-top: 10px;
	}
	/* .navbar-search-none {
    display: none;
  } */
	.navbar-header-class {
		display: flex;
		align-items: flex-start;
		flex-direction: column !important;
	}
	.navbar-header-class .header-link {
		flex-direction: row;
		margin-top: 20px;
		margin-left: 10px;
	}
	.navbar-header-class .header-link:hover {
		color: #000;
	}
	.navbar-search form button {
		min-width: 100px;
	}
	.header-language-container {
		display: flex;
		align-items: flex-start;
		flex-direction: row;
		width: 90%;
		padding: 0 10px;
		border: 1px solid #5a5d63;
		color: #5a5d63;
		border-radius: 8px;
		transition: 0ms;
		margin-top: 10px;
		margin-left: 10px;
	}
	.header-language-container:hover {
		color: black;
	}
	.language-selector {
		width: 100%;
		height: 40px;
		border-radius: 10px;
	}
	.header-language-container .dropdown-language-responsive {
		margin-top: 8px;
		margin-right: 10px;
		display: none;
	}
	.header-language-container-responsive {
		display: none;
	}
	.left-side-icon-responsive {
		margin-right: 10px;
		font-size: 30px;
		color: #5a5d63;
	}
	.navbar-header-class .header-link span .header-page-link {
		font-weight: 600;
		color: #5a5d63;
		font-family: 'poppins', sans-serif;
	}
	.header-link-responsive-none {
		display: none;
	}
	.header-link-responsive-flex {
		display: flex;
		align-items: center;
		margin-left: 10px;
		margin-top: 20px;
	}
	.header-link-responsive-flex span {
		font-weight: 600;
		color: #5a5d63;
		font-family: 'poppins', sans-serif;
	}
	.left-side-bar-network {
		display: flex;
		width: 100%;
		align-items: center;
		justify-content: center;
		margin-top: 160px;
		padding-bottom: 20px;
	}
	.left-side-bar-network-icon {
		font-size: 25px;
		color: #17b978;
		margin-right: 40px;
		cursor: default;
	}
	/*//////////////////////////////////////////////////// Header Style Responsive end \\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\*/
}
@media (min-width: 620px) {
  .header-link-s{
    display: none;
  }
	#navbar-brand{
		margin-right: 10px;
	}
}
