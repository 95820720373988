/* CSS for the Empty Cart Page */
.empty-cart-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40vh;
  background-color: #f5f5f5;
}

.empty-cart-content {
  text-align: center;
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.empty-cart-content h1 {
  font-size: 24px;
  color: #333;
  margin-bottom: 10px;
}

.empty-cart-content p {
  font-size: 16px;
  color: #666;
  margin-bottom: 20px;
}

.back-button {
  display: inline-block;
  padding: 10px 20px;
  background-color: #17b978;
  color: #fff;
  text-decoration: none;
  border-radius: 4px;
  font-size: 16px;
  transition: background-color 0.3s;
}

.back-button:hover {
  background-color: #139e68;
  color: #fff;
}
