.category-1{
    display: grid;
    grid-template-columns: auto auto auto auto;
    gap: 20px;
    margin: 10px 100px 10px 100px;
   
    /* background-color: blue; */
}
@media only screen and (max-width: 600px) {
    .category-1{
        display: grid;
        grid-template-columns: auto auto;
        margin: 10px 10px 10px 10px;
    }
  }
  