.footer {
  background-color: #17b978;
  bottom: -2px;
  position: fixed;
	min-width: 100%;
	z-index: 49;
  display: flex;
  justify-content: space-around;
	border: solid 1.5px white;
	border-radius: 5px;
}

.icon-container {
  color: white;
  text-align: center;
	font-size: 25px;
}

.icon-container span {
  display: block;
  margin-top: 0px;
  font-size: 15px;
	font-weight: 700;
}
@media (min-width: 769px) {
  .footer{
    display: none;
  }
}
