/* CSS for the Checkout Component */
.checkout-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 9999;
}

.checkout-form {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 400px;
  text-align: center;
	z-index: 1;
}

.Toastify {
	position: absolute;
	z-index: 999999;
	font-weight: 900;
}

.checkout-form div {
  margin-bottom: 15px;
}

.checkout-form label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.checkout-form input {
  width: calc(100% - 24px);
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.checkout-form button {
  width: 100%;
  padding: 10px;
  background-color: #17b978;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.checkout-form button:hover {
  background-color: #139e68;
}

.checkout-products {
  margin-bottom: 20px;
}

.checkout-product-item {
  margin-bottom: 5px;
	font-weight: 800;
}

.checkout-total {
  font-weight: bold;
  margin-bottom: 20px;
}
.buttonload {
  background-color: #04AA6D;
  border: none; /* Remove borders */
  color: white; /* White text */
  padding: 12px 24px; /* Some padding */
  font-size: 16px; /* Set a font-size */
}

/* Add a right margin to each icon */
.fa {
  margin-left: -1px;
}
